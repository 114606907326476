export default [
  {
    header: 'Игры',
  },
  {
    title: 'Список игр',
    route: 'games',
    icon: 'CastIcon',
    tagVariant: 'light-warning',
  },
]
