export default [
  {
    header: 'Вопросы',
  },
  {
    title: 'Список вопросов',
    route: 'questions',
    icon: 'HelpCircleIcon',
    tagVariant: 'light-warning',
  },
]
