export default [
  {
    header: 'База <Фильмы>',
  },
  {
    title: 'Фильмы',
    route: 'movies',
    icon: 'FilmIcon',
    tagVariant: 'light-warning',
  },
  {
    title: 'Фильмы (old)',
    route: 'movies-old',
    icon: 'FilmIcon',
    tagVariant: 'light-warning',
  },
  {
    title: 'Премьеры',
    route: 'movies-premiers',
    icon: 'LoaderIcon',
    tagVariant: 'light-warning',
  },
  {
    title: 'Топ фильмов',
    route: 'movies-top',
    icon: 'ArrowUpCircleIcon',
    tagVariant: 'light-warning',
  },
  {
    title: 'Фильтр фильмов',
    route: 'movies-filter',
    icon: 'FilterIcon',
    tagVariant: 'light-warning',
  },
  {
    title: 'Поиск фильмов',
    route: 'movies-search',
    icon: 'SearchIcon',
    tagVariant: 'light-warning',
  },
  {
    title: 'Импорт из Кинопаба',
    route: 'movies-kinopub',
    icon: 'CameraIcon',
    tagVariant: 'light-warning',
  },
  {
    title: 'Импорт Коллекций КП',
    route: 'movies-collections',
    icon: 'CameraIcon',
    tagVariant: 'light-warning',
  },
]
